<template>
  <div>
    <p v-if="showMessage" class="text-left">You can type the year 😊</p>
    <div class="date-multi-select d-flex align-items-center">
      <vSelect
        :value="day"
        class="text-center fs-md"
        label="day"
        :clearable="false"
        :options="days"
        :placeholder="`Day`"
        :multiple="false"
        @input="(e) => updateValue('day', e)"
      >
      </vSelect>

      <vSelect
        :value="month"
        class="month-input text-center fs-md"
        label="month"
        :clearable="false"
        :options="months"
        :placeholder="`Month`"
        :multiple="false"
        @input="(e) => updateValue('month', e)"
      >
      </vSelect>

      <vSelect
        :value="year"
        class="year-input text-center fs-md"
        label="year"
        :clearable="false"
        :options="years"
        :placeholder="`Year`"
        :multiple="false"
        @input="(e) => updateValue('year', e)"
      >
      </vSelect>
    </div>
  </div>
</template>
<script>
import vSelect from "vue-select";
import moment from "moment";

export default {
  name: "DateMultiSelect",
  props: {
    showMessage: { default: true },
    isDOB: [Boolean], //is Day of Birth?
    dateModel: [Date, String, null],
  },
  components: {
    vSelect,
  },
  data() {
    return {
      day: "",
      month: "",
      year: "",
      days: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      months: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
      years: [], //generateArrayOfYears()
    };
  },
  mounted() {
    if (this.dateModel) {
      let date = moment(this.dateModel);

      this.day = date.format("DD");
      this.month = date.format("MM");
      this.year = date.year();
    }

    // if (this.dateModel instanceof Date) {
    //   console.log(this.dateModel);

    //   /* const format = (val) => val.length == 1 ? `0${val}` : val */

    //   let date = moment(this.dateModel)

    //   this.day = date.format('DD') ;
    //   this.month = date.format('MM');
    //   this.year = this.dateModel.getFullYear();
    // }

    const generateArrayOfYears = () => {
      var max = this.isDOB ? new Date().getFullYear() - 10 : new Date().getFullYear();
      var min = max - 100;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push(i.toString());
      }
      this.years = years;
    };

    generateArrayOfYears();
  },
  methods: {
    updateValue(itenName, value) {
      this[itenName] = value;
    },
    formatDate() {
      if (this.day && this.month && this.year) {
        let date = new Date();

        let result = new Date(
          this.year,
          +this.month - 1,
          this.day /* ,
          date.getHours(),
          date.getMinutes() */
        );

        result.setHours(2, 0, 0);

        this.$emit("formattedDate", result);

        // console.log(result)
      }
    },
    clear() {
      this.day = "";
      this.month = "";
      this.year = "";
    },
  },
  watch: {
    dateModel(val) {
      if (val == null) {
        this.clear();
      } else {
        let date = moment(this.dateModel);

        this.day = date.format("DD");
        this.month = date.format("MM");
        this.year = date.year();
      }
    },
    day() {
      this.formatDate();
    },
    month() {
      this.formatDate();
    },
    year() {
      this.formatDate();
    },
  },
};
</script>
<style lang="scss">
.date-multi-select {
  .vs__actions {
    display: none;
  }
  .vs__selected {
    color: black;
  }
  .vs__dropdown-toggle {
    width: 75px;
    padding: 0.2rem 0.4rem !important;
    border-radius: 0.375rem;
    color: black;
    background-color: white;
    margin-right: 5px;
    height: 45px;
  }

  .month-input {
    .vs__dropdown-toggle {
      width: 85px;
    }
  }

  .year-input {
    .vs__dropdown-toggle {
      width: 85px;
    }
  }

  .vs__dropdown-menu {
    color: black !important;
  }
}
</style>
